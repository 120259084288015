<template>
  <div class="checkRecord-box">
    <el-row style="margin-top: 20px">
      <el-form inline :model="tableCondition">
        <!-- <el-form-item label="所属单位" prop="tenantId" class="formItemBoxStyle">
          <el-select
            style="width: 200px"
            placeholder="请输入"
            v-model="tableCondition.tenantId"
          ></el-select>
        </el-form-item> -->
        <el-form-item
          label="编号/名称"
          prop="materialCodeName"
          class="formItemBoxStyle"
        >
          <el-input
            style="width: 200px"
            placeholder="请输入"
            v-model="tableCondition.materialCodeName"
            @blur="handleQueryTableBtn"
          >
          </el-input>
        </el-form-item>
        <el-form-item style="margin-left: 20px">
          <el-button @click="handleResetTableBtn">重置</el-button>
          <el-button type="primary" @click="handleQueryTableBtn"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-table
        :data="dangerMaterialList"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        class="accountTableBox"
        :cell-style="{ 'text-align': 'center' }"
        @expand-change="handleTableExpand"
      >
        <el-table-column type="expand">
          <template slot-scope="scope">
            <div class="placeRoomBox" v-if="!roomData[scope.row.sysExpConsumeMaterialId]">
              加载中...
            </div>
            <div
              class="placeRoomBox"
              v-else-if="roomData[scope.row.sysExpConsumeMaterialId].length == 0"
            >
              暂无存放信息
            </div>
            <div
              class="placeRoomBox"
              v-for="(item, i) in roomData[scope.row.sysExpConsumeMaterialId]"
              :key="i"
            >
              <div style="position: absolute; left: 120px; top: 0">
                存放位置：{{ item.sysOrgSchoolRoomName }}
              </div>
              <div style="position: absolute; left: 520px; top: 0">
                库存数量：{{ item.stockNum }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="所属学校" prop="tenantId">
          <span>{{
              sysOrgSchoolName
            }}</span>
        </el-table-column>
        <el-table-column
          label="国标编号"
          prop="internationCode"
        ></el-table-column>
        <el-table-column label="名称">
          <template slot-scope="scope">
            <span
              style="cursor: pointer; color: #00f; text-decoration: underline"
              @click="handleCheckMaterialStockInfo(scope.row)"
              >{{ scope.row.materialName }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          label="规格功能型号"
          prop="materialSpecifications"
        ></el-table-column>
        <el-table-column label="单位" prop="materialUnit"></el-table-column>
        <!-- <el-table-column label="库存数量" prop="stockNum"></el-table-column>
        <el-table-column
          label="存放位置"
          prop="sysOrgSchoolRoomName"
        ></el-table-column> -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span
              style="cursor: pointer; color: #00f; text-decoration: underline"
              @click="handleCheckUseDetailBtnClick(scope.row)"
              >查看使用明细</span
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChangeTableMsgPage"
        @current-change="handleCurrentChangeTableMsgPage"
        :current-page="tablePageMsg.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="tablePageMsg.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tablePageMsg.pageTotal"
      ></el-pagination>
    </el-row>
    <!-- 查看实验设备详情弹框 -->
    <el-dialog
      title="查看实验设备详情"
      :visible.sync="checkMaterialStockInfoDialogVisible"
    >
      <checkMaterialCom
        :stockInfo="materialStockInfo"
        :imgUrl="editDialogImageList"
      />
    </el-dialog>
    <!-- 查看使用详情弹框 -->
    <el-dialog
      title="使用明细"
      :visible.sync="checkUseDetailDialogVisible"
      width="1200px"
    >
      <el-table
        height="530"
        :data="useDetailDataList"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column
          prop="operateTime"
          label="操作时间"
          :formatter="customFormatter"
        ></el-table-column>
        <el-table-column prop="operateUserName" label="操作人" width="80">
          <template slot-scope="scope">
            <span>{{
              scope.row.operateUserName ? scope.row.operateUserName : "林思思"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="eqptStockInOutBatchCode"
          label="出入库单编号"
        ></el-table-column>
        <el-table-column prop="stockInOutType" label="事由">
          <template slot-scope="scope">
            <span>{{ scope.row.stockInOutType == 1 ? "出库" : "入库" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="keyCode"
          label="关联危化品管理柜编号"
        ></el-table-column>
        <el-table-column prop="video" label="节段视频">
          <template slot-scope="scope">
            <span
              @click="handleVideoDialogOpenClick(scope.row)"
              style="cursor: pointer; color: #00f; text-decoration: underline"
              >查看</span
            >
            <!-- <span v-else>无</span> -->
          </template>
        </el-table-column>
        <el-table-column
          prop="openLockTime"
          label="开锁记录"
          :formatter="customFormatter"
        >
        </el-table-column>
      </el-table>

      <div style="text-align: right; margin-top: 20px">
        <el-button
          @click="
            () => {
              checkUseDetailDialogVisible = false;
            }
          "
          >关闭</el-button
        >
      </div>
    </el-dialog>
    <!-- 查看视频弹框 -->
    <el-dialog title="节段视频" :visible.sync="videoDialogVisible">
      <div style="width: 800px; height: 500px; margin: 0 auto">
        <video controls width="800" :src="videoSrc"></video>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMaterialAccountInfoApi,
  getStockEquipmentDetailApi,
  getMaterialRecordDetailApi,
  getDangerMaterialRecordListApi,
  getMaterialLocationApi,
} from "@/api/materialManage/materialAccount.js";
import { verifyInput, isEmpty, formatDate } from "@/utils/util";
import checkMaterialCom from "../components/checkMaterialCom.vue";
import { getStore } from '@/utils/token.js';
export default {
  name: "checkRecord",
  components: {
    checkMaterialCom,
  },
  data() {
    return {
      tableCondition: {
        materialCodeName: "",
        tenantId: "",
        sysOrgSchoolId:0,
      },
      tablePageMsg: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      dangerMaterialList: [],
      materialStockInfo: {
        affiliatedUnit: "",
        internationCode: "",
        materialHasDanger: "",
        materialHasConsumables: "",
        materialSpecifications: "",
        subjectDicId: "",
        materialUnitPrice: "",
        equipRequir: "",
        keeper: "",
        placeRoomId: "",
        snCode: "",
        materialFeature: "", // 物品特征
      },
      checkMaterialStockInfoDialogVisible: false,
      editDialogImageList: [],
      checkUseDetailDialogVisible: false,
      useDetailDataList: [],
      videoDialogVisible: false,
      videoSrc: "",

      materialAccountCondition: {
        materialCodeName: "",
        equipRequire: "",
        hasDanger: "",
        hasConsumables: "",
        reachStandard: "",
        sysOrgSchoolRoomId: "",
        sysOrgSchoolId:0,
      },
      roomData: {},
      sysOrgSchoolName:"",
    };
  },
  created() {
    this.sysOrgSchoolId = Number(getStore("sysOrgSchoolId"))
    this.sysOrgSchoolName = getStore("sysOrgSchoolName")
    this.tableCondition.sysOrgSchoolId=this.sysOrgSchoolId
    this.materialAccountCondition.sysOrgSchoolId=this.sysOrgSchoolId
    this.fetchDangerMaterialInfoList();
  },
  methods: {
    // 获取盘点记录数据
    fetchDangerMaterialInfoList() {
      let params = {
        ...this.tableCondition,
        hasDanger: true,
      };
      getMaterialAccountInfoApi(
        this.tablePageMsg.pageIndex,
        this.tablePageMsg.pageSize,
        params
      ).then((res) => {
        if (res.success) {
          this.dangerMaterialList = res.data;
          this.tablePageMsg.pageTotal = res.total;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 重置表格
    handleResetTableBtn() {
      this.tableCondition = {
        inventoryStatus: "",
        materialCodeName: "",
        taskName: "",
        school: "",
        sysOrgSchoolId:this.sysOrgSchoolId,
      };
      this.handleQueryTableBtn();
    },
    // 查询表格
    handleQueryTableBtn() {
      this.tablePageMsg.pageIndex = 1;
      this.tablePageMsg.pageSize = 10;
      this.fetchDangerMaterialInfoList();
    },
    handleCurrentChangeTableMsgPage(val) {
      this.tablePageMsg.pageIndex = val;
      this.fetchDangerMaterialInfoList();
    },
    handleSizeChangeTableMsgPage(val) {
      this.tablePageMsg.pageIndex = 1;
      this.tablePageMsg.pageSize = val;
      this.fetchDangerMaterialInfoList();
    },
    // 点击查看节流视频
    handleVideoDialogOpenClick(row) {
      // console.log(row.eqptStockInOutId)
      getDangerMaterialRecordListApi(row.eqptStockInOutId).then((res) => {
        if (res.success) {
          console.log(res);
          this.videoSrc =
            res.data.length > 0
              ? this.$imageAddress(res.data[0].ossFilePath)
              : "";
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
      this.videoDialogVisible = true;
    },
    // 查看使用详情
    handleCheckUseDetailBtnClick(row) {
      // console.log(row)
      getMaterialRecordDetailApi(this.sysOrgSchoolId,row.sysExpConsumeMaterialId).then((res) => {
        if (res.success) {
          // console.log(res);
          this.useDetailDataList = res.data;
          this.checkUseDetailDialogVisible = true;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 点击物品名称进行信息查看
    handleCheckMaterialStockInfo(row) {
      let fd = new FormData();
      fd.append("sysExpConsumeMaterialId", row.sysExpConsumeMaterialId);
      
      fd.append("sysOrgSchoolId", this.sysOrgSchoolId);
      // fd.append("roomId", row.roomId ? row.roomId : 0);
      getStockEquipmentDetailApi(fd).then((res) => {
        if (res.success) {
          const { data } = res;
          this.editDialogImageList = [];
          this.materialStockInfo = {
            materialName: data.materialName,
            affiliatedUnit: data.tenantId,
            internationCode: data.internationCode,
            materialHasDanger: data.materialHasDanger ? "是" : "否",
            materialHasConsumables: data.materialHasConsumables ? "是" : "否",
            materialSpecifications: data.materialSpecifications,
            subjectDicId: data.subjectTypeDicName,
            materialUnitPrice: data.materialUnitPrice,
            equipRequire: data.equipRequire == 1 ? "基本" : "选配",
            keeper: data.keeper,
            placeRoomId: data.roomName,
            sysOrgSchoolRoomName: data.sysOrgSchoolRoomName,
            snCode: data.snCode,
            materialFeature: data.materialItemTagDTOS, // 物品特征
          };
          data.imgUrl.forEach((item) => {
            this.editDialogImageList.push(this.$imageAddress(item));
          });
          console.log(this.materialStockInfo, "res");
          this.checkMaterialStockInfoDialogVisible = true;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    handleTableExpand(row, expandedRows) {
      if (this.roomData[row.sysExpConsumeMaterialId]) {
        return;
      }
      getMaterialLocationApi(
        row.sysExpConsumeMaterialId,
        this.materialAccountCondition
      ).then((res) => {
        if (res.success) {
          this.$set(this.roomData, row.sysExpConsumeMaterialId, res.data);
          this.$forceUpdate();
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    handleSelectPlaceRoom() {
      // this.$refs.materialTableList.toggleAllSelection()  toggleRowExpansion
      this.materialTableList.forEach((item) => {
        this.$refs.materialTableList.toggleRowExpansion(item, false);
      });
      this.roomData = {};
      this.handleQueryMaterialAccount();
    },
    customFormatter(row, column) {
      switch (column.property) {
        case "operateTime":
          if (isEmpty(row.operateTime)) {
            return "/";
          }
          return formatDate(new Date(row.operateTime), "yyyy/MM/dd");
        // case "type":
        //   switch (row.type) {
        //     case 1:
        //       return "出库";
        //     case 2:
        //       return "入库";
        //   }
        case "operateType":
          switch (row.operateType) {
            case 101:
              return "采购";
            case 102:
              return "归还";
            case 103:
              return "其他";
          }
        case "openLockTime":
          if (isEmpty(row.openLockTime)) {
            return "/";
          }
          return formatDate(new Date(row.openLockTime), "yyyy/MM/dd hh:mm:ss");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.placeRoomBox {
  padding-left: 120px;
  height: 50px;
  line-height: 50px;
  position: relative;
  border-bottom: 1px solid rgb(235, 238, 245);
  &:last-child {
    border-bottom: none;
  }
}
::v-deep .accountTableBox {
  .el-table__cell.el-table__expanded-cell {
    padding: 0;
  }
}
</style>
